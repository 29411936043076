.projects-main {
  width: 100%;
  text-align: center;
}

.basic-projects {
  margin-left: 5%;
  margin-right: 5%;
}

.projects-heading-div {
  display: flex;
}

.projects-heading-div > * {
  flex: 1;
}

.projects-heading-img-div {
  align-items: center;
  justify-content: center;
}

.projects-heading-text-div {
  text-align: center;
}

.projects-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.projects-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
}

.projects-heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.projects-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}
.publications-heading-text-div {
  text-align: center;
  font-family: "Google Sans Regular";
  margin-top: 70px;
}

.publications-heading-text {
  font-size: 56px;
  font-weight: 600;
}
.repo-cards-div-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
}


.container {
  box-sizing: border-box;
  padding: 2rem;
  color: #001c55;
  font-weight: bold;
  margin-top: 3rem;
  /* font-family: "Open Sans", sans-serif; */
}
.cardsgrid {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}
a {
  text-decoration: none;
  color: #001c55;
}
.card {
  background-color: #a6e1fa;
  border-radius: 5px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease-out;
}
.card:hover {
  box-shadow: 0 15px 40px -20px gray;
}
.icon {
  height: 1.5rem;
}
.iconTools {
  height: 2.5rem;
}
.projectlabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.projecttitle {
  font-weight: bold;
  font-size: 26px;
  margin-left: 0.5rem;
}
.projectdescription {
  margin: 1rem 0 2rem 0;
  /* align-self: flex-start; */
  text-align: left;
}
.dataAndTools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.spanCreatedDate {
  font-weight: bold;
  color: #7f8daa;
  font-size: 16px;
}

@media screen and (max-width: 900px) {
  .cardsgrid {
    grid-template-columns: repeat(1, 1fr);
  }
}


@media (max-width: 1380px) {
  .projects-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .projects-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .projects-heading-text,
  .publications-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .projects-heading-sub-text {
    font-size: 20px;
  }

  .projects-heading-div {
    flex-direction: column;
  }

  .projects-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    margin-left: 0px;
    line-height: normal;
    text-align: center;
  }

  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
  }
}
